<template>
  <v-layout row wrap justify-center align-start>
    <v-flex xs12 lg12 md12 class="mb-3">
      <v-card>
        <titleCard title="Rutas de reparto " subtitle="Listado de rutas realizadas el dia XX al XX"> </titleCard>
        <v-divider></v-divider>
        <v-card-text>
          <v-layout row wrap text-xs-center>
            <v-flex xs3>
              <v-select
                v-model="dealer"
                :items="dealers"
                item-text="name"
                item-value="id"
                label="Repartidor"
                hide-details
              >
              </v-select>
            </v-flex>
            <v-flex xs3>
              <v-select
                v-model="car"
                :items="cars"
                :item-text="it => `${it.patente} - ${it.marca} ${it.modelo}`"
                item-value="id"
                label="Vehiculo"
                hide-details
              >
              </v-select>
            </v-flex>
            <v-flex xs2>
              <v-text-field v-model="dateStart" type="date" label="Fecha desde" placeholder=""></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-text-field v-model="dateEnd" type="date" label="Fecha hasta" placeholder=""></v-text-field>
            </v-flex>
            <v-flex xs2>
              <v-btn class="mt-2" color="success" block @click="getInfo">
                Filtrar
              </v-btn>
            </v-flex>
          </v-layout>
          <v-layout row wrap text-xs-center>
            <v-flex xs12>
              <v-data-table
                :headers="headers"
                :items="items"
                class="elevation-0 order-table"
                color="secondary"
                :disable-initial-sort="true"
                no-data-text="No hay rutas ingresadas"
                :pagination.sync="pagination"
                :rows-per-page-items="rowsPerPage"
              >
                <template slot="items" slot-scope="props">
                  <tr>
                    <td class="text-xs-left">
                      <v-chip v-if="props.item.status === 0" color="warning" text-color="white">
                        Aun no inicia
                      </v-chip>
                      <v-chip v-if="props.item.status === 1" color="error" text-color="white">
                        Ruta iniciada
                      </v-chip>
                      <v-chip v-if="props.item.status === 2" color="info" text-color="white">
                        En camino a finalizar
                      </v-chip>
                      <v-chip v-if="props.item.status === 3" color="success" text-color="white">
                        Finalizada
                      </v-chip>
                    </td>
                    <td class="text-xs-left">
                      {{ props.item.date_start }}
                    </td>
                    <td @click="changeStatus(props.item)">
                      {{ props.item.date_end }}
                    </td>
                    <td class="text-xs-right">
                      {{ props.item.orders.length }}
                    </td>
                    <td class="text-xs-right">
                      <v-btn icon flat small :to="{ name: 'storeDealersRoutesDetail', params: { id: props.item.id } }">
                        <v-icon small>visibility</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
  </v-layout>
</template>

<script>
import { MI_STORE } from '../../config'
import titleCard from '../useful/titleCard.vue'
import appStatus from '../useful/status.vue'
import appTitleDialog from '../useful/titleDialog.vue'
import FileReader from '../useful/FileReader.vue'

export default {
  name: 'DealerRoutes',
  components: {
    titleCard,
    appStatus,
    appTitleDialog,
    FileReader
  },
  data() {
    return {
      modalDate: false,
      dateStart: this.$moment()
        .subtract(3, 'days')
        .format('YYYY-MM-DD'),
      dateEnd: this.$moment()
        .subtract(1, 'days')
        .format('YYYY-MM-DD'),
      headers: [
        { text: 'Estado', align: 'left', sortable: false },
        { text: 'Fecha Inicio', align: 'left', sortable: false },
        { text: 'Fecha Fin', align: 'left', sortable: false },
        { text: 'Pedidos', align: 'center', sortable: false },
        { text: '', align: 'right', sortable: false }
      ],
      dealers: [],
      car: null,
      dealer: 1,
      pagination: { rowsPerPage: 50 },
      rowsPerPage: [25, 50, 100, 200, { text: 'Todos', value: -1 }],
      items: []
    }
  },
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    cars() {
      return this.$store.getters.getCars
    }
  },
  mounted() {
    if (this.$route.query.repartidor) {
      this.dealer = parseInt(this.$route.query.repartidor)
    }
    this.getDealers()
    this.getInfo()
  },
  methods: {
    async getDealers() {
      try {
        const res = await this.$http(`${MI_STORE}/dealers`)
        this.dealers = this._.filter(res.data, ['status', true])
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getInfo() {
      try {
        const params = {
          repartidor: this.dealer,
          dateStart: this.dateStart,
          dateEnd: this.dateEnd,
          car: this.car
        }
        const res = await this.$http.get(`${MI_STORE}/routes`, { params })
        if (res.data.routes) {
          this.items = res.data.routes
        }
        console.log(res)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    }
  }
}
</script>
